<template>
  <div class="payInfoForm">
    <div>
      <v-card class="bankingCard pa-4" color="grey lighten-4">
        <div class="d-flex justify-center pb-2">
          <span class="text-h5 font-weight-bold paymentHeadline"
            >무통장입금 안내</span
          >
        </div>
        <v-divider class="mb-4" />
        <div class="d-flex">
          <div class="pr-3">
            <v-icon size="35" color="red">mdi-bullhorn</v-icon>
          </div>
          <span class="bankingcardBodyText">
            무통장입금의 경우 고객님의
            <span class="font-weight-bold">'입금자명'</span>과
            <span class="font-weight-bold">'입금액'</span>이 정확하게 일치해야
            정상적으로 입금 확인이 가능합니다. <br />입금 확인은 대부분 빠른
            시간 내에 처리되나, 간혹 지속적으로 입금 확인이 되지 않는 경우
            고객센터(1660-1866)에 문의 부탁드립니다.
          </span>
        </div>
        <div class="mt-4">
          <div class="pb-2">
            <span class="text-h5 font-weight-bold blue--text text--lighten-2">
              입금 계좌 안내
            </span>
          </div>
          <v-divider class="mb-4" />
          <div class="bankingGrid">
            <div>
              <img src="@/assets/image/png/bankIcon.png" width="250" />
            </div>
            <div>
              <div>
                <span class="font-weight-bold">예금주 : </span>
                <span class="bankingcardBodyText">소프트마니 주식회사</span>
              </div>
              <div>
                <span class="font-weight-bold">계좌번호 : </span>
                <span class="bankingcardBodyText">216-131234-01-011</span>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>

    <v-card class="pa-4 mb-2">
      <div class="pb-2">
        <span class="font-weight-bold text-h6">결제</span>
      </div>
      <v-divider class="mb-4" />
      <div>
        <v-text-field
          value="원단마니아 서비스이용 (1년)"
          label="결제상품"
          readonly
          hide-details
          class="mb-4"
        ></v-text-field>
        <v-text-field
          :value="period"
          label="이용 기간"
          readonly
          hide-details
          class="mb-4"
        ></v-text-field>
        <v-text-field
          v-model="payForm.name"
          label="입금자명"
          hide-details
          class="mb-4 required-field"
        ></v-text-field>
        <v-text-field
          v-model="payForm.price"
          label="입금액"
          prefix="₩"
          @input="(e) => (payForm.price = usingComma(e))"
          hide-details
          class="mb-4 required-field"
        ></v-text-field>
      </div>
      <div class="d-flex justify-center">
        <v-btn
          color="#2c98f0"
          width="120"
          height="40"
          class="mt-4"
          @click="pay('deposit')"
          :disabled="
            $store.state.isPayment.membership === true ||
            $store.state.isPayment.request === true
          "
        >
          <span class="white--text font-weight-bold">
            {{
              $store.state.isPayment.request === true
                ? "입금확인중"
                : $store.state.isPayment.membership === true
                ? "현재 구독중"
                : "입금 확인요청"
            }}</span
          >
        </v-btn>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      payForm: { name: null, price: null, type: null, period: null },
    };
  },
  computed: {
    period() {
      const date = new Date(Date.now());
      const endDate = new Date(Date.now()).setFullYear(date.getFullYear() + 1);
      return `${this.dateFormat(date)} ~ ${this.dateFormat(endDate)}`;
    },
  },
  methods: {
    checkpayForm() {
      return !this.payForm.name || !this.payForm.price ? false : true;
    },
    async pay(type) {
      if (!this.checkpayForm()) {
        this.$store.commit("setSnackBar", "입금자명과 입금액은 필수 값입니다.");
        return false;
      }
      if (confirm("입력하신 내용으로 입금확인 요청 하시겠습니까?")) {
        this.payForm.type = type;
        this.payForm.period = this.period;

        this.$store.dispatch("SET_API_LOADING", true);

        await this.$store.dispatch("PAY_SAVE", this.payForm);

        this.$store.dispatch("SET_API_LOADING", false);

        await this.$store.dispatch("PAYMENT_STATUS_CHECK");
      }
    },
  },
};
</script>
