<template>
  <div class="payBodyGrid">
    <div class="payBodyCard">
      <div class="payTitleCard">
        <div>
          <div class="payTitleText">
            <span>구독타입</span>
          </div>
        </div>
      </div>
      <div
        class="payCard"
        @click="changeSubType('1MonthPrice')"
        :class="{ selected: selectedCard === '1MonthPrice' }"
      >
        <div class="d-flex align-center pr-4">
          <v-icon size="30">mdi-cached</v-icon>
        </div>
        <div>
          <div class="label">
            <span>1개월 결제</span>
          </div>
          <div class="body">
            {{ "₩99000원" | makeComma }} / 1개월 (VAT 포함)
          </div>
        </div>
        <template v-if="selectedCard === '1MonthPrice'">
          <div class="ml-auto mr-4">
            <v-icon size="28" color="blue">mdi-check-circle-outline</v-icon>
          </div>
        </template>
      </div>
      <div
        class="payCard"
        :class="{ selected: selectedCard === '3monthPrice' }"
        @click="changeSubType('3monthPrice')"
      >
        <div class="d-flex align-center pr-4">
          <v-icon size="30">mdi-cached</v-icon>
        </div>
        <div>
          <div class="label">
            <span>3개월 결제</span>
          </div>
          <div class="body">
            {{ "₩297000원" | makeComma }} / 3개월 (VAT 포함)
          </div>
        </div>
        <template v-if="selectedCard === '3monthPrice'">
          <div class="ml-auto mr-4">
            <v-icon size="28" color="blue">mdi-check-circle-outline</v-icon>
          </div>
        </template>
      </div>
      <div
        class="payCard"
        :class="{ selected: selectedCard === '12monthPrice' }"
        @click="changeSubType('12monthPrice')"
      >
        <div class="d-flex align-center pr-4">
          <v-icon size="30">mdi-cached</v-icon>
        </div>
        <div>
          <div class="label">
            <span>12개월 결제</span>
          </div>
          <div class="body">
            {{ "₩1188000원" | makeComma }} / 12개월 (VAT 포함)
          </div>
        </div>
        <template v-if="selectedCard === '12monthPrice'">
          <div class="ml-auto mr-4">
            <v-icon size="28" color="blue">mdi-check-circle-outline</v-icon>
          </div>
        </template>
      </div>
      <paymentMethod />
    </div>
    <div class="payInfoForm pl-6 pr-6">
      <template v-if="selectedPay === 'creditCard'">
        <SubscribeInfo />
      </template>
      <template v-if="selectedPay === 'bankTransfer'">
        <bankTransferInfo />
      </template>
    </div>
  </div>
</template>
<script>
import SubscribeInfo from "../payInfo/subscribeInfo.vue";
import paymentMethod from "../paymentMethod.vue";
import bankTransferInfo from "../payInfo/bankTransferInfo.vue";
import servicePrices from "@/assets/data/price/ServicePrice";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      selectedPay: "getSelectedPayType",
    }),
  },
  components: {
    SubscribeInfo,
    bankTransferInfo,
    paymentMethod,
  },
  data() {
    return {
      selectedCard: "monthPrice",
    };
  },
  methods: {
    changeSubType(type) {
      this.selectedCard = type;
      const selectPrice = servicePrices.find((data) => data.label === type);
      this.$store.commit("setSelectSubType", selectPrice);

      this.$store.commit("setServicePrice", selectPrice.value);
    },
  },
};
</script>
