<template>
  <div class="myInfoSetting">
    <!-- 계정 설정 -->
    <div class="settingGreyCard mt-4">
      <div class="d-flex">
        <div class="titleText"><span>계정정보 변경</span></div>
        <div class="userButtonArea">
          <v-btn color="#2c98f0" @click="modifyUser()" width="80" height="30">
            <span class="whiteText">저장</span>
          </v-btn>
        </div>
      </div>

      <v-text-field :value="$store.state.auth_uuid" label="아이디" disabled />

      <v-text-field v-model="user.name" label="이름" />

      <v-text-field
        v-model="user.tel"
        @input="user.tel = getPhoneMask($event)"
        label="전화번호"
        counter="13"
        maxlength="13"
      />

      <div class="pb-4">
        <v-btn color="red" width="80" height="30" to="/deleteAccount">
          <span class="whiteText">회원탈퇴</span>
        </v-btn>
      </div>
    </div>

    <!-- 비밀번호 변경 -->
    <div class="settingGreyCard">
      <div class="d-flex">
        <div class="titleText"><span>비밀번호 변경</span></div>
        <div class="userButtonArea">
          <v-btn
            color="#2c98f0"
            @click="modifyPasswordCall()"
            :disabled="isModifyPwBtn()"
            width="80"
            height="30"
          >
            <span class="whiteText">저장</span>
          </v-btn>
        </div>
      </div>

      <v-text-field
        v-model="existingPw"
        label="현재 비밀번호"
        @keypress.enter="passwordCheck()"
        type="password"
        :hint="existingHint"
        :disabled="existingCheck"
        :persistent-hint="true"
      >
        <template v-slot:append>
          <v-btn
            width="50"
            height="25"
            :disabled="existingCheck"
            @click.prevent.stop="passwordCheck()"
            color="rgba(0, 0, 0, 0.1)"
            class="confirmButton"
          >
            <span class="greyText">확인</span>
          </v-btn>
        </template>
      </v-text-field>

      <v-text-field
        v-model="modifyPw"
        label="변경 비밀번호"
        type="password"
        :hint="modifyPwHint()"
        :disabled="!existingCheck"
        :persistent-hint="true"
      />

      <v-text-field
        v-model="checkPw"
        label="변경 비밀번호 확인"
        type="password"
        :hint="checkPwHint()"
        :disabled="!existingCheck"
        :persistent-hint="true"
      />
    </div>

    <!-- 회사 설정 -->
    <div class="settingGreyCard mt-4">
      <div class="company">
        <div class="buttonRows pb-4">
          <div class="titleText"><span>회사 정보</span></div>
          <div class="companyButtonArea">
            <v-btn
              color="#2c98f0"
              @click="saveCompanyForm('company')"
              width="80"
              height="30"
              :disabled="$store.state.auth_grade < 5 || $store.state.isExpired"
            >
              <span class="whiteText">저장</span>
            </v-btn>
          </div>
        </div>

        <div class="companyField">
          <v-text-field
            dense
            label="회사명"
            v-model="company.name"
            type="text"
            :readonly="$store.state.auth_grade < 5"
          />
          <v-text-field
            dense
            label="대표자명"
            v-model="company.officer"
            type="text"
            :readonly="$store.state.auth_grade < 5"
          />
          <v-text-field
            dense
            label="사업자번호"
            v-model="company.business_num"
            type="text"
            :readonly="$store.state.auth_grade < 5"
            @input="company.business_num = getBusinessNumMask($event)"
          />
          <v-text-field
            dense
            label="연락 담당자"
            v-model="company.liaison"
            type="text"
            :readonly="$store.state.auth_grade < 5"
          />
          <v-text-field
            dense
            label="전화번호"
            v-model="$store.state.viewTel"
            type="text"
            @input="(e) => ($store.state.viewTel = getPhoneMask(e))"
            maxlength="13"
            :readonly="$store.state.auth_grade < 5"
          />
          <v-text-field
            dense
            label="주소"
            v-model="company.address"
            type="text"
            :readonly="$store.state.auth_grade < 5"
          />
          <v-text-field
            dense
            label="팩스번호"
            v-model="company.fax"
            type="text"
            :readonly="$store.state.auth_grade < 5"
          />
        </div>
      </div>
    </div>

    <!-- 로고 변경 -->
    <div class="settingGreyCard">
      <div class="pb-8 d-flex">
        <div class="titleText">로고 변경</div>
        <div class="companyButtonArea">
          <v-btn
            color="#2c98f0"
            @click="saveCompanyForm('logo')"
            width="80"
            height="30"
            :disabled="$store.state.auth_grade < 5 || $store.state.isExpired"
          >
            <span class="whiteText">저장</span>
          </v-btn>
        </div>
      </div>

      <div class="d-flex">
        <div class="mr-4">
          <v-img
            v-if="preview"
            :src="preview"
            width="160"
            height="100"
            contain
            class="bordered-img"
          />
          <v-img
            v-else
            src="@/assets/image/png/no-image.png"
            width="160"
            height="100"
            contain
            class="bordered-img"
          />
          <div><span class="labelText">최적 크기: 160x100</span></div>
        </div>

        <div style="width: 100%">
          <v-select
            v-model="company.logoType"
            :items="logoTypeList"
            dense
            item-text="label"
            return-object
            label="로고 타입 선택"
            :disabled="$store.state.auth_grade < 5"
          />
          <v-file-input
            v-model="saveLogoImg"
            accept="image/png, image/jpeg"
            prepend-icon="mdi-camera"
            label="로고 이미지"
            class="pt-0 mt-0 mb-10"
            :disabled="$store.state.auth_grade < 5"
            @change="changeLogo"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkUserPw, modifyPassword, putUser } from "@/api/user";
import { postfile, deletefile, downloadFile } from "@/api/file";
import { validatePassword } from "@/utils/validation";
import { mapGetters } from "vuex";
import { putCompany, postCompany } from "@/api/company";
export default {
  computed: {
    ...mapGetters({
      user: "getUserInfo",
      company: "getEditCompany",
    }),
  },
  data() {
    return {
      existingPw: "",
      existingCheck: false,
      modifyPw: "",
      checkPw: "",
      existingHint: "현재 비밀번호를 입력 후 확인해주세요",
      saveLogoImg: null,
      preview: "",
      logoTypeList: [
        { value: 1, label: "로고 이미지 / 로고 텍스트" },
        { value: 2, label: "로고 이미지" },
        { value: 3, label: "로고 텍스트" },
      ],
    };
  },
  methods: {
    checkPwHint() {
      let hint = "변경 될 비밀번호를 입력해주세요";
      if (this.checkPw && this.checkPw !== this.modifyPw)
        hint = "변경 비밀번호와 다릅니다. 다시 확인해주세요";
      if (this.checkPw && this.checkPw === this.modifyPw)
        hint = "변경될 비밀번호와 동일합니다.";
      return hint;
    },
    modifyPwHint() {
      let hint = "";

      if (this.modifyPw) hint = "변경 비밀번호를 확인 해주세요";
      if (validatePassword(this.modifyPw))
        hint = "비밀번호는 8~20자 이상 영문,숫자,특수문자형태로 입력해주세요";
      if (this.modifyPw && this.modifyPw === this.existingPw)
        hint = "현재 비밀번호와 동일합니다. 새로운 비밀번호를 입력해주세요";
      return hint;
    },
    // 패스워드 변경 확인
    async passwordCheck() {
      if (!this.existingPw) return;
      try {
        const result = await checkUserPw({
          uuid: this.$store.state.auth_uuid,
          password: this.existingPw,
        });

        if (result.status !== 200) throw `error: ${result.status}`;

        this.existingCheck = result.data;
        if (!this.existingCheck)
          this.existingHint =
            "현재 비밀번호가 맞지 않습니다. 다시 입력해주세요";
        else this.existingHint = "변경할 비밀번호를 입력해주세요";
      } catch (e) {
        console.log(e);
      }
    },
    // 패스워드 변경
    async modifyPasswordCall() {
      if (confirm("비밀번호를 변경하시겠습니까?")) {
        try {
          const result = await modifyPassword({
            uuid: this.$store.state.auth_uuid,
            password: this.modifyPw,
          });

          if (result.status !== 200) throw `error: ${result.status}`;

          if (result.data) {
            alert("비밀번호가 변경되었습니다. 다시 로그인해주세요");
            this.$store.dispatch("LOGOUT");
            this.$router.push("/login");
          } else {
            alert("비밀번호가 변경되지 않았습니다. 다시 시도 해주세요");
            this.initPassword();
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    async modifyUser() {
      if (confirm("계정정보를 변경하시겠습니까?")) {
        try {
          const result = await putUser({
            ...this.user,
          });

          if (result.status !== 200) throw `error: ${result.status}`;

          await this.$store.dispatch("GET_FIND_USER_INFO");
          this.$store.commit("setSnackBar", `계정 정보가 변경되었습니다.`);
        } catch (e) {
          console.log(e);
        }
      }
    },
    initPassword() {
      this.existingPw = ""; // 현재 비밀번호
      this.existingCheck = false; // 비밀번호 확인
      this.modifyPw = ""; // 변경 비밀 번호
      this.checkPw = ""; // 변경 비밀번호 확인
      this.existingHint = "현재 비밀번호를 입력 후 확인해주세요";
    },
    isModifyPwBtn() {
      let isBtn = true;

      // 변경 비밀번호와 확인 비밀번호 같을 경우
      if (this.modifyPw && this.modifyPw === this.checkPw) isBtn = !isBtn;
      // 변경 비밀번호와 현재 비밀번호가 같을경우
      if (this.modifyPw === this.existingPw) isBtn = true;
      //특수, 숫자, 영뮨 포함 확인
      if (validatePassword(this.modifyPw)) isBtn = true;

      return isBtn;
    },
    changeLogo() {
      if (this.saveLogoImg) {
        const fileData = (data) => {
          this.preview = data;
        };
        const reader = new FileReader();

        reader.readAsDataURL(this.saveLogoImg);
        reader.addEventListener(
          "load",
          function () {
            fileData(reader.result);
          },
          false,
        );
      } else {
        this.preview = "";
        this.getLogoImg();
      }
    },
    async getLogoImg() {
      if (this.logoImg._id) {
        try {
          const result = await downloadFile(
            this.$store.state.auth_company,
            this.logoImg._id,
          );

          if (result.status !== 200) throw `error: ${result.status}`;
          const downFile = new Blob([result.data]);

          this.preview = window.URL.createObjectURL(downFile);
        } catch (e) {
          console.log(e);
        }
      }
    },
    async saveCompanyForm(type) {
      if (
        confirm(
          `${
            type === "company" ? "회사 정보를" : "로고 변경을"
          } 저장하시겠습니까?`,
        )
      ) {
        if (!this.$store.state.viewBusinessNum) {
          this.snackbar_text = `사업자 번호를 입력해주세요.`;
          this.snackbar = true;
          return;
        }
        if (!this.company.name) {
          this.snackbar_text = `회사 명을 입력해주세요.`;
          this.snackbar = true;
          return false;
        }
        this.company.business_num = this.$store.state.viewBusinessNum;
        this.company.tel = this.$store.state.viewTel;
        this.company.id = this.$store.state.auth_company;

        await this.postCompany();
        if (this.saveLogoImg) this.saveLogo();

        this.$store.commit(
          "setSnackBar",
          `${
            type === "company" ? "회사 정보가" : "로고 변경이"
          } 저장되었습니다.`,
        );

        await this.$store.dispatch("GET_COMPANY");
      }
    },
    async saveLogo() {
      if (this.logoImg) this.logoDelete();
      try {
        const result = await postfile(this.saveFormData(this.saveLogoImg));

        if (result.status !== 200) throw `error: ${result.status}`;

        this.saveLogoImg = null;
        await this.setPrivew();
      } catch (e) {
        console.log(e);
      }
    },

    async logoDelete() {
      try {
        const result = await deletefile(this.logoImg._id);

        if (result.status !== 200) throw `error: ${result.status}`;
      } catch (e) {
        console.log(e);
      }
    },
    async postCompany() {
      try {
        const result = this.company._id
          ? await putCompany(this.company)
          : await postCompany(this.company);
        if (result.status !== 200) throw `error : ${result.status}`;
      } catch (e) {
        alert(e);
      }
    },
  },
};
</script>

<style></style>
