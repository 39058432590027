export default [
  {
    label: "monthPrice",
    value: 99000,
    name: "원단마니아 정기결제(1개월)",
  },
  {
    label: "yearPrice",
    value: 1188000,
    name: "원단마니아 정기결제(1년)",
  },
  {
    label: "1MonthPrice",
    value: 99000,
    name: "원단마니아 월결제(1개월)",
  },
  {
    label: "3monthPrice",
    value: 297000,
    name: "원단마니아 월결제(3개월)",
  },
  {
    label: "12monthPrice",
    value: 1188000,
    name: "원단마니아 월결제(12개월)",
  },
];
