<template>
  <v-container fluid class="listContainer">
    <div class="inputTitle">오더 - 입력설정</div>
    <div class="settingGreyCard">
      <div class="headerRight pb-2">
        <v-btn
          color="#2c98f0"
          width="80"
          height="30"
          class="headerRight"
          @click="saveCompanyForm"
        >
          <span class="white--text">저장</span>
        </v-btn>
      </div>
      <div class="inputBodyGrid">
        <div class="labelConfirmButton">
          <div>사용</div>
          <div>
            <input
              class="v-checkbox"
              type="checkbox"
              style="transform: scale(1.1)"
              v-model="company.input_order_status"
            />
          </div>
        </div>
        <div class="mt-2">
          <v-card outlined variant="tonal" color="grey lighten-5 ">
            <v-card-title> 오더번호 직접입력 설정 </v-card-title>

            <v-card-text>
              <span class="grey--text">
                이 기능을 사용 시 오더번호가 자동입력 된 번호가 아닌 직접 입력된
                번호로 나타납니다.
              </span>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
    <div class="inputTitle">생산 - 가공의뢰서 입력설정</div>
    <div class="settingGreyCard">
      <div class="headerRight">
        <v-btn
          color="#2c98f0"
          width="80"
          height="30"
          class="headerRight"
          @click="saveCompanyForm"
        >
          <span class="white--text">저장</span>
        </v-btn>
      </div>
      <div class="inputBodyGrid">
        <div class="labelConfirmButton">
          <div>사용</div>
          <div>
            <input
              class="v-checkbox"
              type="checkbox"
              v-model="company.manu_confirm_status"
              style="transform: scale(1.1)"
            />
          </div>
        </div>

        <div>
          <v-textarea
            rows="5"
            label="확인사항"
            hide-details
            class="lefted-input"
            clearable
            no-resize
            background-color="grey lighten-5"
            v-model="company.manu_confirm"
          />
        </div>

        <div class="labelConfirmButton">
          <div>사용</div>
          <div>
            <input
              class="v-checkbox"
              type="checkbox"
              style="transform: scale(1.1)"
              v-model="company.manu_pre_status"
            />
          </div>
        </div>
        <div>
          <v-textarea
            rows="5"
            label="주의사항"
            hide-details
            class="lefted-input"
            clearable
            no-resize
            background-color="grey lighten-5"
            v-model="company.manu_pre"
          />
        </div>
      </div>
    </div>
    <div class="inputTitle">생산 - 출고의뢰서 입력설정</div>

    <div class="settingGreyCard">
      <div class="headerRight">
        <v-btn
          color="#2c98f0"
          width="80"
          height="30"
          class="headerRight"
          @click="saveCompanyForm"
        >
          <span class="white--text">저장</span>
        </v-btn>
      </div>
      <div class="inputBodyGrid">
        <div class="labelConfirmButton">
          <div>사용</div>
          <div>
            <input
              class="v-checkbox"
              type="checkbox"
              style="transform: scale(1.1)"
              v-model="company.release_pre_status"
            />
          </div>
        </div>
        <div>
          <v-textarea
            rows="5"
            label="주의사항"
            hide-details
            class="lefted-input"
            clearable
            no-resize
            background-color="grey lighten-5"
            v-model="company.release_pre"
          />
        </div>
      </div>
    </div>
    <div class="inputTitle">프린트 설정 - 폼 타입 구분</div>
    <div class="settingGreyCard">
      <div class="imageCardArea">
        <div />
        <div />
        <div class="printTypeCard" @click="checkPrintType('1')">
          <div class="printTypeTitle">
            <h4>A 타입</h4>
            <div class="d-flex ml-auto">
              <input
                class="customRadio"
                name="printType"
                type="radio"
                id="A"
                value="1"
                v-model="companySetting.print_type"
              />
            </div>
          </div>
          <div class="imageCard">
            <img
              src="@/assets/image/png/release_document_new.png"
              style="width: 100%; height: 100%"
            />
          </div>
        </div>
        <div class="printTypeCard" @click="checkPrintType('2')">
          <div class="printTypeTitle">
            <h4>B 타입</h4>
            <div class="d-flex ml-auto">
              <input
                v-model="companySetting.print_type"
                class="customRadio"
                name="printType"
                type="radio"
                id="B"
                value="2"
              />
            </div>
          </div>
          <div class="imageCard">
            <img
              src="@/assets/image/png/release_document.png"
              style="width: 100%; height: 100%"
            />
          </div>
        </div>
        <div class="inputRightButton">
          <v-btn
            color="#2c98f0"
            width="80"
            height="30"
            class="headerRight"
            @click="saveCallSetting('printType')"
          >
            <span class="white--text">저장</span>
          </v-btn>
        </div>
      </div>
    </div>

    <div class="inputTitle">프린터 출고의뢰서 폼 - 바이어 설정</div>
    <div class="settingGreyCard">
      <div class="headerRight">
        <v-btn
          color="#2c98f0"
          width="80"
          height="30"
          class="headerRight"
          @click="saveCallSetting('buyer')"
        >
          <span class="white--text">저장</span>
        </v-btn>
      </div>

      <div class="inputBodyGrid">
        <div class="labelConfirmButton">
          <div>사용</div>
          <div>
            <input
              class="v-checkbox"
              type="checkbox"
              v-model="companySetting.is_noBuyer"
              style="transform: scale(1.1)"
            />
          </div>
        </div>

        <div class="pt-2">
          <v-card outlined variant="tonal" color="grey lighten-5 ">
            <v-card-title> 아이템 설정</v-card-title>

            <v-card-text>
              <span class="grey--text">
                이 기능을 사용 시 출고의뢰서의 브랜드명이 나타나지 않습니다.
              </span>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>

    <div class="inputTitle">프린터 가공의뢰서 폼 - 결제 영역설정</div>
    <div class="settingGreyCard">
      <div class="headerRight">
        <v-btn
          color="#2c98f0"
          width="80"
          height="30"
          class="headerRight"
          @click="saveCompanyForm"
        >
          <span class="white--text">저장</span>
        </v-btn>
      </div>
      <div class="inputBodyGrid">
        <div class="inputPayment"></div>
        <div class="mt-2">
          <div class="d-flex">
            <div v-for="(item, i) in paymentList" :key="i">
              <div class="printPayTopBlock">
                <v-text-field
                  solo
                  dense
                  v-model="item.name"
                  hide-details
                  class="printPayText"
                >
                </v-text-field>
              </div>
              <div class="printPayTopBotttom"></div>
            </div>
            <div class="d-flex justify-center align-center flex-column">
              <div class="d-flex align-center">
                <v-btn icon @click="paymentPlus">
                  <v-icon color="green"> mdi-plus </v-icon>
                </v-btn>
              </div>
              <div class="d-flex align-center">
                <v-btn icon @click="paymentSlice">
                  <v-icon color="red"> mdi-minus </v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inputTitle">프린터 가공의뢰서 폼 - 아이템 설정</div>
    <div class="settingGreyCard">
      <div class="headerRight">
        <v-btn
          color="#2c98f0"
          width="80"
          height="30"
          class="headerRight"
          @click="saveCompanyForm"
        >
          <span class="white--text">저장</span>
        </v-btn>
      </div>

      <div class="inputBodyGrid">
        <div class="labelConfirmButton">
          <div>사용</div>
          <div>
            <input
              class="v-checkbox"
              type="checkbox"
              v-model="company.manu_item"
              style="transform: scale(1.1)"
            />
          </div>
        </div>

        <div class="pt-2">
          <v-card outlined variant="tonal" color="grey lighten-5 ">
            <v-card-title> 아이템 설정</v-card-title>

            <v-card-text>
              <span class="grey--text">
                이 기능을 사용 시 생산처 ITEM NO가 아닌 자사 ITEM NO로
                설정됩니다.
              </span>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { putCompany, postCompany } from "@/api/company";
import { postSetting, putSetting } from "@/api/companySetting";
export default {
  data() {
    return {
      paymentList: [],
      selectPrintType: {},
    };
  },
  computed: {
    ...mapGetters({
      company: "getEditCompany",
      companySetting: "getCompanySetting",
    }),
  },
  methods: {
    async postCompany() {
      try {
        const result = this.company._id
          ? await putCompany(this.company)
          : await postCompany(this.company);
        if (result.status !== 200) throw `error : ${result.status}`;
      } catch (e) {
        alert(e);
      }
    },
    async saveCallSetting(type) {
      if (!this.selectPrintType)
        return this.$store.commit("setSnackBar", `설정 값이 부족합니다.`);

      if (confirm("프린터 설정을 저장하시겠습니까?")) {
        let form = {};

        form.id = this.$store.state.company.id;
        form.type = type;

        if (this.companySetting) form._id = this.companySetting._id;

        if (type === "printType")
          form.printType = this.companySetting.print_type;
        if (type === "buyer") form.is_noBuyer = this.companySetting.is_noBuyer;

        await this.saveSetting(form);
        this.$store.commit("setSnackBar", `프린터 설정이 저장 되었습니다.`);
        await this.$store.dispatch("GET_SETTING_COMPANY");
      }
    },
    checkPrintType(type) {
      this.companySetting.print_type = type;
    },
    async saveCompanyForm() {
      if (confirm("기본 설정을 저장하시겠습니까?")) {
        this.company.id = this.$store.state.auth_company;
        if (this.company.print_paymentList) {
          this.company.print_paymentList = this.paymentList.filter(
            (data) => data.name.trim() !== "",
          );
        }

        await this.postCompany();
        this.$store.commit("setSnackBar", `기본 설정이 저장 되었습니다.`);
        await this.$store.dispatch("GET_COMPANY");
      }
    },
    async saveSetting(form) {
      try {
        const result =
          this.companySetting.id && this.companySetting.print_type
            ? await putSetting(form)
            : await postSetting(form);
        if (result.status !== 200) throw `error : ${result.status}`;
      } catch (e) {
        alert(e);
      }
    },
    paymentPlus() {
      this.paymentList.push({ name: "" });
    },
    paymentSlice() {
      this.paymentList.pop();
    },
  },
  created() {
    this.paymentList = this.company.print_paymentList.length
      ? [...this.company.print_paymentList]
      : [
          { name: "담 당" },
          { name: "팀 장" },
          { name: "차 장" },
          { name: "실 장" },
          { name: "사 장" },
        ];
  },
};
</script>
