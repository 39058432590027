<template>
  <div v-if="visible" class="modal-overlay">
    <div class="modal-content">
      <button class="close-button" @click="closeModal">×</button>
      <div v-if="iframeSrc" class="iframe-container">
        <iframe
          ref="paymentIframe"
          class="payment-iframe"
          frameborder="0"
        ></iframe>
      </div>
      <div v-else class="loading-message">결제 페이지를 불러오는 중...</div>
    </div>
  </div>
</template>

<script>
import { cardReadyDanal } from "@/api/pay";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      payParam: "getSendPayParam",
    }),
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      iframeSrc: "", // iframe에 렌더링할 URL
      startParams: "", // POST 요청에 필요한 데이터
    };
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.loadPaymentPage();
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async loadPaymentPage() {
      try {
        const response = await cardReadyDanal(this.payParam); // readyDanal 호출
        const data = response.data.data;

        if (data.RETURNCODE === "0000") {
          this.iframeSrc = data.STARTURL; // 반환된 URL 설정
          this.startParams = data.STARTPARAMS; // POST 요청에 필요한 데이터 설정
          this.renderIframeForm(); // iframe 내부에 form 생성 및 제출
        } else {
          console.error("결제 URL을 가져오는 데 실패했습니다.");
        }
      } catch (error) {
        console.error("결제 요청 중 오류가 발생했습니다:", error);
      }
    },
    renderIframeForm() {
      // iframe 내부에 form 생성 및 제출
      this.$nextTick(() => {
        const iframe = this.$refs.paymentIframe;
        if (!iframe) {
          console.error("Iframe이 아직 생성되지 않았습니다.");
          return;
        }
        const iframeDoc =
          iframe.contentDocument || iframe.contentWindow.document;

        iframeDoc.open();
        iframeDoc.write(`
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>결제 진행</title>
      </head>
      <body>
        <form id="paymentForm" action="${this.iframeSrc}" method="POST">
          <input type="hidden" name="STARTPARAMS" value="${this.startParams}" />
        </form>
      </body>
    </html>
  `);
        iframeDoc.close();

        const script = iframeDoc.createElement("script");
        script.innerHTML = "document.getElementById('paymentForm').submit();";
        iframeDoc.body.appendChild(script);
      });
    },
    handleMessage(event) {
      if (event.data && event.data.closeModal) {
        this.closeModal();
      }
    },
  },
  mounted() {
    window.addEventListener("message", this.handleMessage);
  },
  beforeUnmount() {
    window.removeEventListener("message", this.handleMessage);
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  min-width: 660px;
  max-width: 800px;
  height: 490px;
  max-width: 1200px; /* 최대 너비 제한 */
  max-height: 800px; /* 최대 높이 제한 */
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  display: flex; /* iframe을 꽉 채우기 위해 flex 사용 */
  flex-direction: column;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  z-index: 10; /* 닫기 버튼이 iframe 위에 표시되도록 설정 */
}

.iframe-container {
  flex: 1; /* 부모 요소의 남은 공간을 모두 차지 */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* 부모 요소의 너비를 100%로 설정 */
  height: 100%; /* 부모 요소의 높이를 100%로 설정 */
}

.payment-iframe {
  width: 100%; /* 부모 요소의 너비를 100%로 설정 */
  height: 100%; /* 부모 요소의 높이를 100%로 설정 */
  border: none;
}
.loading-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
