<template>
  <div class="formContainer">
    <v-tabs v-model="tabs" bg-color="primary">
      <v-tab value="service">서비스 이용정보</v-tab>
      <v-tab value="payment">결제</v-tab>
      <v-tab value="paymentList">결제 내역</v-tab>
    </v-tabs>
    <v-divider />
    <div class="container pt-4 pb-0">
      <div class="serviceArea">
        <template v-if="tabs === 0">
          <v-card class="inputCard">
            <div class="mt-2 serviceBody">
              <span class="bankingcardBodyTextLael">사용중인 서비스 </span>
              <template v-if="!$store.state.company.is_membership">
                <div class="pb-2"></div>
                <div class="d-flex">
                  <span class="ServicebodyTitle pr-2">
                    {{
                      $store.state.company.is_trial
                        ? "무료 체험중"
                        : "무료 체험 기간 만료"
                    }}
                  </span>
                  <div
                    class="titleCard"
                    :class="!$store.state.company.is_trial ? 'red--text' : ''"
                  >
                    <template v-if="$store.state.company.is_trial">
                      구독중
                    </template>
                    <template v-else> 만료 </template>
                  </div>
                  <v-btn
                    color="#2c98f0"
                    width="120"
                    height="30"
                    class="headerRight"
                    @click="tabs = 1"
                  >
                    <span class="white--text font-weight-bold">결제하기</span>
                  </v-btn>
                </div>
                <span>
                  {{
                    $store.state.company.is_trial
                      ? "(무료 체험중)"
                      : "(무료 체험 기간 만료)"
                  }}
                  {{
                    $store.state.company.is_trial
                      ? "1개월간 원단마니아 서비스를 무료로 이용할 수 있습니다."
                      : "결제 시 모든 서비스를 이용할 수 있습니다."
                  }}
                </span>
              </template>
              <template v-if="$store.state.company.is_membership">
                <div class="membershipInfoGrid">
                  <div>
                    <span class="bankingcardBodyTextBold"> 서비스명 : </span>
                  </div>
                  <div>
                    <span class="font-weight-black">
                      {{ membershipInfo.serviceName }}
                    </span>
                  </div>

                  <div>
                    <span class="bankingcardBodyTextBold"> 사용 기간 : </span>
                  </div>
                  <div>
                    <span class="">{{ membershipInfo.useDate }} </span>
                  </div>
                  <div>
                    <span class="bankingcardBodyTextBold"> 남은 기간 : </span>
                  </div>
                  <div>
                    <span class="font-weight-black">
                      {{
                        membershipInfo.remaining_period
                          ? `${membershipInfo.remaining_period} 일`
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    <span class="bankingcardBodyTextBold">
                      다음 결제 예정일 :
                    </span>
                  </div>
                  <div>
                    <span class=""> {{ membershipInfo.next_order_date }} </span>
                  </div>
                  <div>
                    <span class="bankingcardBodyTextBold">
                      결제 예정 금액 :
                    </span>
                  </div>
                  <div>
                    <span class="">
                      {{
                        membershipInfo.expected_price
                          ? `₩${membershipInfo.expected_price}`
                          : "" | makeComma
                      }}
                    </span>
                  </div>
                  <div class="bankingCardLabel">
                    <span class="bankingcardBodyTextLael">
                      서비스 해지를 원하시면
                      <a class="bankingCardlLinkText" @click="unsubscribe"
                        ><u>여기</u></a
                      >를 클릭해주세요.
                    </span>
                  </div>
                </div>
              </template>
            </div>
          </v-card>
          <v-card class="infoCard">
            <div class="mt-2 serviceBody">
              <div class="d-flex">
                <v-icon color="#4fbec5" size="40">mdi-credit-card-check</v-icon>
                <span class="pl-4">
                  <span class="bankingcardBodyTextLael">결제 수단변경 </span>
                  <p />
                  &nbsp;
                  <p class="bankingcardBodyTextLael">&nbsp;</p>
                </span>
                <v-btn
                  color="#2c98f0"
                  width="120"
                  height="30"
                  class="headerRight"
                  @click="tabs = 1"
                >
                  <span class="white--text font-weight-bold">변경하기</span>
                </v-btn>
              </div>
            </div>
          </v-card>
        </template>
        <template v-if="tabs === 1">
          <payment />
        </template>
        <template v-if="tabs === 2">
          <paymentList />
        </template>
      </div>
    </div>
    <canclePay />
  </div>
</template>
<style lang="scss" src="../../common/scss/pay.scss"></style>
<script>
import { mapGetters } from "vuex";
import payment from "./payment.vue";
import canclePay from "@/components/organisms/VDialog/canclePay.vue";
import paymentList from "./paymentList.vue";

export default {
  components: {
    payment,
    paymentList,
    canclePay,
  },
  computed: {
    ...mapGetters({
      membershipInfo: "getMembershipInfo",
    }),
  },
  data() {
    return {
      tabs: "service",
    };
  },
  created() {
    this.$store.dispatch("CALL_MEMBERSHIP_INFO");
  },
  methods: {
    unsubscribe() {
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "unsubscribe",
      });
    },
  },
};
</script>

<style></style>
