<template>
  <div>
    <div>
      <div class="inputTitle text-h6 font-weight-medium mb-4">결제 선택</div>
      <v-radio-group
        :value="payKind"
        @change="payKindChange($event)"
        style="height: 100%"
      >
        <div class="payTypeGrid">
          <div class="payTypeCard">
            <v-radio label="월 결제" value="singlePay"></v-radio>
          </div>
          <!-- <div class="payTypeCard">
            <v-radio label="정기결제" value="subscribe"></v-radio>
          </div> -->
        </div>
      </v-radio-group>
    </div>
    <template v-if="payKind === 'singlePay'">
      <singlePay />
    </template>
    <template v-if="payKind === 'subscribe'">
      <subscribe />
    </template>
  </div>
</template>
<script>
import subscribe from "./payTypePage/subscribe.vue";
import singlePay from "./payTypePage/singlePay.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    subscribe,
    singlePay,
  },
  data() {
    return {
      payTypeChoice: "",
    };
  },

  computed: {
    ...mapGetters({
      payKind: "getSelectedPayKind",
    }),
  },
  methods: {
    payKindChange(value) {
      this.$store.commit("setSelectedPayKind", value);
    },
  },
  created() {
    this.$store.dispatch("PAYMENT_STATUS_CHECK");
  },
};
</script>
