<template>
  <v-dialog
    :value="dialog_rebillCardInput"
    @click:outside="$store.state.isDialog = false"
    @keydown.esc="$store.state.isDialog = false"
    @keydown.enter="saveEditForm"
    max-width="1800px"
  >
    <v-card>
      <v-card-title> 카드 입력 </v-card-title>
      <v-card-text>
        <div>
          <v-text-field label="카드번호" v-model="cardNumber" />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    dialog_rebillCardInput() {
      return this.$store.state.isDialog === true &&
        this.$store.state.isKind === "dialog_rebillCardInput"
        ? true
        : false;
    },
  },
  data() {
    return {
      cardNumber: "",
    };
  },
};
</script>
