<template>
  <div style="width: 100%">
    <div class="payTitleCard">
      <div>
        <div class="payTitleText">
          <span>결제방법</span>
        </div>
      </div>
    </div>
    <div class="paymentTypeCard">
      <div class="payTitleText pt-2 pb-4"></div>
      <div class="typeGrid">
        <div
          v-for="type in payType"
          :key="type.value"
          class="type"
          @click="changePayType(type)"
          :class="{ selected: selectedPay === type.value }"
        >
          {{ type.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      selectedPay: "getSelectedPayType",
    }),
  },
  data() {
    return {
      payType: [
        { name: " 신용/체크카드", value: "creditCard" },
        // { name: " 네이버페이", value: "naverPay" },
        // { name: " 토스페이", value: "tossPay" },
        // { name: " 페이코", value: "payco" },
        // { name: " 카카오페이", value: "kakaoPay" },
        { name: " 무통장입금", value: "bankTransfer" },
      ],
    };
  },
  methods: {
    changePayType(type) {
      this.$store.commit("setSelectedPayType", type.value);
    },
  },
};
</script>
