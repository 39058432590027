<template>
  <v-dialog
    :value="serviceterm_dialog"
    max-width="800px"
    scrollable
    @click:outside="$store.state.isDialog = false"
    @keydown.esc="$store.state.isDialog = false"
  >
    <v-card>
      <v-card-title class="pa-0">
        <div class="grey lighten-2 d-flex align-center" style="width: 100%">
          <div class="ml-auto pr-2">
            <v-btn
              icon
              color="red"
              large
              @click="$store.state.isDialog = false"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-title>
      <v-card-text class="pt-4">
        <h2>제1조 (목적)</h2>
        <p />
        <p>
          본 약관은 소프트마니 주식회사(이하 "회사")가 제공하는 정기결제 및 일반
          결제 서비스(이하 "서비스") 이용과 관련하여 회사와 회원 간의 권리 및
          의무 기타 필요한 사항을 규정함을 목적으로 합니다.
        </p>

        <h2>제2조 (정의)</h2>
        <p />
        <ul>
          <li>
            "서비스"란 회원이 회사 및 회사와 계약을 체결한 제휴사에서 상품 및
            서비스를 구매하는 경우, 이를 용이하게 할 수 있도록 회사가 제공하는
            결제 관련 서비스를 말합니다.
          </li>
          <li>
            "정기결제 서비스"란 회원이 상품 및 서비스를 주기적으로 구매할 경우,
            결제 정보를 매번 입력하지 않고 자동으로 결제가 이루어지도록 하는
            서비스를 말합니다.
          </li>
          <li>
            "일반 결제 서비스"란 회원이 특정 상품 및 서비스를 일회성으로 구매할
            때 이용하는 결제 서비스를 말합니다.
          </li>
          <li>
            "회원"이란 서비스에 가입하여 본 약관에 동의하고, 회사로부터 서비스
            이용 권한을 부여받은 자를 말합니다.
          </li>
          <li>
            "아이디"란 회원의 식별과 서비스 이용을 위하여 회원이 설정하고 회사가
            승인하여 등록된 회원의 식별정보를 말합니다.
          </li>
          <li>
            "비밀번호"란 회원이 회사의 서비스를 이용하는 경우 필요한 인증
            수단으로써, 본인 여부를 확인받고 회사 전자결제시스템에 등록한 숫자
            조합을 말합니다.
          </li>
          <li>
            "제휴사"란 회사와 제휴하여 본 약관에 의한 서비스를 이용하는 회사를
            말합니다.
          </li>
        </ul>
        <p />
        <h2>제3조 (약관의 명시 및 변경)</h2>

        <p />
        <p>
          1. 회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 페이지 등에
          게시합니다.
        </p>
        <p>
          2. 회사는 관련 법령을 위배하지 않는 범위에서 본 약관을 개정할 수
          있습니다.
        </p>
        <p>
          3. 회사가 본 약관을 개정하는 경우 변경되는 약관의 시행일 30일 전에
          이를 게시하고 회원에게 알립니다.
        </p>

        <h2>제4조 (서비스 이용계약의 체결)</h2>
        <p />
        <p>
          1. 서비스 이용계약은 회원이 회사의 사이트에서 아이디와 비밀번호를
          기입한 후 본 약관에 동의하고, 회사가 요청하는 결제 관련 정보를
          등록하여 서비스 이용을 신청한 후, 회사가 이를 승낙하면 체결됩니다.
        </p>
        <p>
          2. 회원은 정기결제 서비스 및 일반 결제 서비스를 선택하여 이용할 수
          있습니다.
        </p>

        <h2>제5조 (서비스의 종류 및 이용방법)</h2>
        <p />
        <ul>
          <li>
            정기결제 서비스: 회원이 회사 및 제휴사에서 주기적으로 상품을
            구매하는 경우, 자동 결제를 통해 결제가 이루어지도록 하는 서비스
          </li>
          <li>
            일반 결제 서비스: 회원이 특정 상품 및 서비스를 일회성으로 구매할 때
            이용하는 결제 서비스
          </li>
          <li>
            기타 서비스: 회사가 제공하는 결제수단 종류에 따른 결제 대행 서비스
            등
          </li>
        </ul>
        <p />
        <h2>제6조 (결제 및 청구)</h2>
        <p />
        <p>
          1. 회원이 정기결제 서비스를 이용하는 경우, 회사는 사전에 등록된 결제
          정보를 바탕으로 정해진 주기에 따라 자동으로 결제를 진행합니다.
        </p>
        <p>
          2. 회원이 일반 결제 서비스를 이용하는 경우, 회원이 결제 요청을 완료한
          시점에 결제가 이루어집니다.
        </p>
        <p>
          3. 회원은 결제 전에 결제 수단의 유효성을 확인하여야 하며, 결제가
          거절된 경우 서비스 이용이 제한될 수 있습니다.
        </p>

        <h2>제7조 (서비스 이용계약의 해지 및 해제 등)</h2>
        <p />
        <p>
          1. 회원은 회사가 정한 방법으로 해지 신청을 하여 서비스 이용계약을
          해지할 수 있습니다.
        </p>
        <p>
          2. 회사는 회원이 본 약관 또는 관계 법령을 위반하는 경우 서비스
          이용계약을 해지할 수 있습니다.
        </p>

        <h2>제8조 (회사의 의무)</h2>
        <p />
        <p>
          회사는 본 약관 및 관련 법을 준수하고, 회원의 개인정보 보호를 위해
          최선을 다합니다.
        </p>

        <h2>제9조 (회원의 의무)</h2>
        <p />
        <p>
          회원은 서비스 이용 시 본인 명의의 결제수단을 사용해야 하며, 비밀번호를
          제3자에게 유출해서는 안 됩니다.
        </p>

        <h2>제10조 (회원의 금지행위)</h2>
        <p />
        <ul>
          <li>
            서비스 이용을 위해 허위사실을 기재하거나 타인의 정보를 도용하는 행위
          </li>
          <li>
            회사의 서비스 운영을 방해할 수 있는 컴퓨터 바이러스, 광고성 스팸
            정보 등을 유포하는 행위
          </li>
        </ul>
        <p />
        <h2>제11조 (회사의 배상 책임)</h2>
        <p />
        <p>
          회사는 전자금융거래법 등에 따라 회원의 결제정보를 보호하며, 회사의
          과실로 발생한 손해에 대해 배상할 책임을 집니다.
        </p>

        <h2>제12조 (회원의 배상 책임)</h2>
        <p />
        <p>
          회원이 본 약관을 위반하여 회사에 손해를 끼친 경우, 회원은 회사의
          손해를 배상해야 합니다.
        </p>

        <h2>제13조 (서비스 이용 대금에 대한 이의 신청 및 책임)</h2>
        <p />
        <p>
          회원은 서비스 이용으로 인한 결제 대금에 이의가 있을 경우 결제일로부터
          14일 이내에 회사에 이의를 제기할 수 있습니다.
        </p>

        <h2>제14조 (관할)</h2>
        <p />
        <p>
          회사와 회원 간 발생한 분쟁은 관련 법령이 정한 절차에 따라 해결하며,
          관할 법원은 본사 소재지를 관할하는 법원으로 합니다.
        </p>

        <h2>부칙</h2>
        <p />
        <p>제1조 본 약관은 2025.03.12부터 시행됩니다.</p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      serviceTerms_dialog: false,
    };
  },
  computed: {
    serviceterm_dialog() {
      return this.$store.state.isDialog === true &&
        this.$store.state.isKind === "serviceterm_dialog"
        ? true
        : false;
    },
  },
};
</script>
