<template>
  <div style="width: 100%">
    <div class="payInfoCard mb-4">
      <div class="payTitleText pt-2 pb-2">결제 정보</div>
      <div class="divider" />
      <div class="label mr-auto pt-2 pb-2">
        <span>서비스 연장 기간</span>
      </div>
      <div class="serviceInfo">
        <div class="serviceBody">
          <div class="d-flex justify-center align-center flex-column">
            <div>
              <span class="body">추가할 기간</span>
            </div>

            <div>
              <span class="label"> 365일 </span>
            </div>
          </div>
        </div>
        <!-- <v-icon size="30" class="innerIcon">mdi-arrow-right</v-icon> -->

        <div class="serviceBody">
          <div class="d-flex justify-center align-center flex-column">
            <div>
              <span class="body">결제 후 만료일</span>
            </div>
            <div>
              <span class="label"> 2025년 12월 1일</span>
            </div>
          </div>
        </div>

        <div class="price">
          <span class="label ml-4"> 결제 예정 금액 (VAT포함) </span>
          <span class="mr-4 ml-auto">
            <span class="payTitleText">{{ servicePrice | makeComma }}</span>
            <span class="body">원</span>
          </span>
        </div>
      </div>

      <div class="label mr-auto pt-4 pb-2">
        <span>부가 서비스</span>
      </div>
      <div class="addonServiceInfo">
        <div class="addonBody">
          <div class="d-flex justify-center align-center flex-column">
            <div>
              <span class="body">기존 인원</span>
            </div>
            <div>
              <span class="label"> {{ currentMemberCount }}명 </span>
            </div>
          </div>
        </div>
        <div class="addonBody">
          <div
            class="d-flex justify-center align-center flex-column"
            style="flex: 1 0 auto"
          >
            <div>
              <span class="body">추가 인원</span>
            </div>
            <div class="d-flex justify-center align-center">
              <div class="d-flex align-center pr-1">
                <a @click="subMember"><v-icon>mdi-minus</v-icon></a>
              </div>
              <div class="text-center" style="flex: 1">
                <span class="label">
                  {{ `${addMemberCount}명` }}
                </span>
              </div>
              <div class="d-flex align-center pl-1">
                <a @click="plusMember"><v-icon>mdi-plus</v-icon></a>
              </div>
            </div>
          </div>
        </div>
        <div class="addonBody">
          <div class="d-flex justify-center align-center flex-column">
            <div>
              <span class="body">추가 후 인원</span>
            </div>
            <div>
              <span class="label">
                {{ currentMemberCount + addMemberCount }}명
              </span>
            </div>
          </div>
        </div>
        <div class="price">
          <span class="label ml-4"> 결제 예정 금액 (VAT포함) </span>
          <span class="mr-4 ml-auto">
            <span class="payTitleText">{{ memberPrice | makeComma }}</span>
            <span class="body">원</span>
          </span>
        </div>
      </div>
      <div class="label mr-auto pt-4 pb-2">
        <span>최종 결제금액</span>
      </div>
      <div class="priceInfo">
        <span class="label ml-4"> 결제 예정 금액 (VAT포함) </span>
        <span class="mr-4 ml-auto">
          <span class="payTitleText">{{ sumServicePrice | makeComma }}</span>
          <span class="body">원</span>
        </span>
      </div>
      <div class="termsArea">
        <div class="d-flex align-center">
          <input
            class="v-checkbox"
            type="checkbox"
            :checked="serviceTerms"
            @click="serviceTerms = !serviceTerms"
          />
          <span class="pl-2 text-subtitle-2 greyText" style="text-wrap: nowrap"
            >서비스 이용약관을 확인하였으며 결제에 동의합니다.</span
          >
          &nbsp;<a @click="termsOpen()">&lt;서비스 이용약관&gt; </a>
        </div>
        <div class="d-flex align-center">
          <input
            class="v-checkbox"
            type="checkbox"
            :checked="cautionTerms"
            @click="cautionTerms = !cautionTerms"
          />
          <span class="pl-2 text-subtitle-2 greyText" style="text-wrap: nowrap">
            <template v-if="payKind === 'subscribe'">
              결제 주의사항을 확인하였으며 매뭘 정기결제되는 것에 동의합니다.
            </template>
            <template v-if="payKind === 'singlePay'">
              결제 금액을 확인하였으며 동의합니다.
            </template>
          </span>
        </div>
      </div>
      <div class="mt-4">
        <v-btn
          color="#2c98f0"
          width="120"
          height="30"
          class="headerRight"
          @click="requestPayment"
        >
          <span class="white--text font-weight-bold">결제하기</span>
        </v-btn>
      </div>
    </div>
    <serviceTerms />
    <rebillCardInputDialog />
    <PaymentModal
      :visible="isPaymentModalVisible"
      @close="isPaymentModalVisible = false"
    />
  </div>
</template>
<script>
import PaymentModal from "@/components/organisms/VDialog/paymentDialog.vue";
import { mapGetters } from "vuex";
import serviceTerms from "../../../../organisms/VDialog/serviceTerms.vue";
import rebillCardInputDialog from "@/components/organisms/VDialog/rebillCardInputDialog.vue";
export default {
  components: {
    PaymentModal,
    serviceTerms,
    rebillCardInputDialog,
  },
  data() {
    return {
      serviceTerms: false,
      cautionTerms: false,
      addMemberCount: 0,
      currentMemberCount: 4,
      isPaymentModalVisible: false, // 모달 표시 여부
    };
  },
  computed: {
    ...mapGetters({
      servicePrice: "getServicePrice",
      selectedPay: "getSelectedPayType",
      selectSubType: "getSelectSubType",
      payKind: "getSelectedPayKind",
    }),
    memberPrice() {
      return this.addMemberCount * 22000;
    },
    sumServicePrice() {
      return this.servicePrice + this.memberPrice;
    },
  },
  methods: {
    validateTerms() {
      if (this.serviceTerms && this.cautionTerms) {
        return true;
      }
      return false;
    },
    async requestPayment() {
      if (!this.validateTerms()) {
        this.$store.commit("setSnackBar", "이용약관에 동의해주세요.");
        return;
      }
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const date = today.getDate();
      const time = today.getTime();
      const orderID = `${this.payKind}_${year}_${month}_${date}_${time}`;

      if (this.payKind === "subscribe") {
        // if() 추후에 카드 정보 최초 입력인지 확인하는 조건 추가
        this.$store.commit("setDailogStatus", {
          status: true,
          kind: "dialog_rebillCardInput",
        });
        return;
      }

      // const payParam = this.payKind === "subscribe" ? {
      //   ORDERID : orderID,
      //   ITEMNAME : this.selectedPay
      //   USERNAME : this.$store.state.auth
      //   USERPHONE:
      //   USEREMAIL:
      //   USERID:
      //   USERAGENT: "PC"
      // } : {
      //   serviceName: this.selectSubType.name,
      //   price: this.sumServicePrice,

      // };

      this.isPaymentModalVisible = true; // 모달 표시

      this.$store.commit("setSendPayParam", {
        serviceName: this.selectSubType.name,
        price: this.sumServicePrice,
      });
    },
    termsOpen() {
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "serviceterm_dialog",
      });
    },
    plusMember() {
      this.addMemberCount++;
    },
    subMember() {
      if (this.addMemberCount > 0) this.addMemberCount--;
    },
    // openDanalPayment() {
    //   const paymentParams = {
    //     CPID: "9810030929",
    //     AMOUNT: "10000",
    //     ITEMNAME: "테스트 상품",
    //     USERID: "testuser",
    //     USERNAME: "홍길동",
    //     PAYMETHOD: "CC",
    //     RETURNURL: "https://yourwebsite.com/payment/success",
    //   };

    //   let paymentUrl = "https://ui.teledit.com/Danal/Teledit/Web/Start.php";
    //   for (const key in paymentParams) {
    //     paymentUrl += `${key}=${encodeURIComponent(paymentParams[key])}&`;
    //   }

    //   window.open(paymentUrl, "DanalPayment", "width=600,height=800");
    // },
  },
};
</script>
