<template>
  <v-dialog
    :value="unsubscribe_dialog"
    max-width="800px"
    @click:outside="$store.state.isDialog = false"
    @keydown.esc="$store.state.isDialog = false"
  >
    <v-card height="500">
      <v-card-title class="pa-0">
        <div class="ml-auto pr-2">
          <v-btn icon color="red" large @click="$store.state.isDialog = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text class="pt-4">
        <h2>서비스 자동결제를 해지하시겠습니까?</h2>
        <p />
        <div class="cancleInfoCard">
          <div class="d-flex">
            <div class="pl-2">
              <div>
                <v-icon size="30" color="#2c98f0">mdi-check</v-icon>
                <span class="pl-2">
                  1. 서비스 자동결제를 해지하시면 다음 결제일에 서비스가
                  자동으로 연장되지 않습니다.
                </span>
              </div>
              <div>
                <v-icon size="30" color="#2c98f0">mdi-check</v-icon>
                <span class="pl-2">
                  2. 결제일 이전 기간 내 서비스는 계속 이용 가능합니다.
                </span>
              </div>
              <div>
                <v-icon size="30" color="#2c98f0">mdi-check</v-icon>
                <span class="pl-2">
                  3. 결제 해지 시 기존의 결제수단은 모두 삭제되며, 다시 결제 시
                  재등록해야합니다.
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-4">
          <customSelectField
            v-model="cancelReason"
            :items="cancelReasonList"
            label="해지 사유를 선택해주세요."
            :height="'40px'"
            :width="'100%'"
          />
        </div>
        <template v-if="cancelReason === 'etc'">
          <div class="pt-2">
            <v-textarea
              v-model="otherReason"
              label="기타 사유를 입력해주세요."
              outlined
              dense
              rows="3"
              hide-details
            >
            </v-textarea>
          </div>
        </template>
        <div class="pt-4">
          <input
            class="v-checkbox"
            type="checkbox"
            :checked="cautionTerms"
            @click="cautionTerms = !cautionTerms"
          />
          <span class="pl-2 text-subtitle-2 greyText" style="text-wrap: nowrap"
            >위의 주의사항을 확인했습니다.</span
          >
        </div>
      </v-card-text>
      <v-card-actions class="justify-center">
        <div>
          <v-btn
            color="red"
            width="120"
            height="30"
            class="headerRight"
            @click="requestCancel"
            :disabled="checkConfirm()"
          >
            <span class="white--text font-weight-bold">취소하기</span>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import customSelectField from "@/components/atoms/Select/customSelectField.vue";
export default {
  components: {
    customSelectField,
  },
  data() {
    return {
      cancelReason: "",
      otherReason: "",
      cautionTerms: false,
      cancelReasonList: [
        {
          label: "서비스 미이용",
          value: "notUseService",
        },
        {
          label: "서비스 불만족",
          value: "notSatisfiedService",
        },
        {
          label: "가격이 부담됨",
          value: "priceBurden",
        },
        {
          label: "기타",
          value: "etc",
        },
      ],
    };
  },
  computed: {
    unsubscribe_dialog() {
      return this.$store.state.isDialog === true &&
        this.$store.state.isKind === "unsubscribe"
        ? true
        : false;
    },
  },
  methods: {
    checkConfirm() {
      if (!this.cancelReason) {
        return true;
      }
      if (this.cancelReason === "etc" && this.otherReason === "") {
        return true;
      }
      if (!this.cautionTerms) {
        return true;
      }
      return false;
    },
    requestCancel() {},
  },
};
</script>
